import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Shield, Globe, Brain, ChevronRight } from 'lucide-react';
import Footer from '../components/footer';

const ServicesPage = () => {
  const [expandedService, setExpandedService] = useState(null);

  const services = [
    {
      icon: <Globe size={48} />,
      title: 'Web Development',
      description: 'Crafting responsive and intuitive web solutions tailored to your business needs.',
      details: [
        'Custom website design and development',
        'E-commerce solutions',
        'Content Management Systems (CMS)',
        'Progressive Web Applications (PWA)',
        'API development and integration'
      ]
    },
    {
      icon: <Shield size={48} />,
      title: 'Cyber Security',
      description: 'Protecting your digital assets with state-of-the-art security measures and protocols.',
      details: [
        'Vulnerability assessments and penetration testing',
        'Security audits and compliance',
        'Incident response and forensics',
        'Security awareness training',
        'Managed security services'
      ]
    },
    {
      icon: <Brain size={48} />,
      title: 'AI Development',
      description: 'Harnessing the power of artificial intelligence to drive innovation and efficiency.',
      details: [
        'Machine learning model development',
        'Natural Language Processing (NLP) solutions',
        'Computer vision applications',
        'Predictive analytics',
        'AI-powered chatbots and virtual assistants'
      ]
    },
  ];

  return (
    <div style={{
      backgroundColor: '#1a1a1a',
      color: 'white',
      minHeight: '100vh',
      fontFamily: 'Arial, sans-serif',
      display: 'flex',
      flexDirection: 'column',
    }}>
      {/* Hero Section */}
      <section className="relative md:py-44 py-32 bg-[url('../../assets/images/bg/aboutusbg.png')] bg-no-repeat bg-bottom bg-cover">
    <div className="container relative">
        <div className="grid grid-cols-1 text-center mt-6">
            <div>
                <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">Our Services</h5>
            </div>
            <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
                <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                    <Link to="/">NexGen Guards</Link>
                </li>
                <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                    <i className="mdi mdi-chevron-right"></i>
                </li>
                <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white" aria-current="page">
                    Services
                </li>
            </ul>
        </div>
    </div>
</section>

      <main style={{
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '60px 20px',
        flex: '1 0 auto',
      }}>
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '30px',
        }}>
          {services.map((service, index) => (
            <div
              key={index}
              style={{
                backgroundColor: '#333',
                borderRadius: '8px',
                width: '300px',
                height: '300px',
                position: 'relative',
                overflow: 'hidden',
                transition: 'all 0.5s ease-in-out',
                cursor: 'pointer',
              }}
              onMouseEnter={() => setExpandedService(index)}
              onMouseLeave={() => setExpandedService(null)}
            >
              <div style={{
                padding: '30px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                transition: 'transform 0.5s ease-in-out',
                transform: expandedService === index ? 'translateY(-100%)' : 'translateY(0)',
              }}>
                <div style={{ color: '#ff6600', marginBottom: '20px' }}>
                  {service.icon}
                </div>
                <h3 style={{
                  color: 'white',
                  fontSize: '1.5rem',
                  marginBottom: '15px',
                }}>{service.title}</h3>
                <p style={{
                  color: '#ccc',
                  fontSize: '1rem',
                  lineHeight: '1.6',
                }}>{service.description}</p>
              </div>
              <div style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                right: 0,
                height: '100%',
                padding: '30px',
                backgroundColor: '#333',
                transition: 'transform 0.5s ease-in-out',
                transform: expandedService === index ? 'translateY(-100%)' : 'translateY(0)',
                overflow: 'auto',
              }}>
                <h4 style={{
                  color: '#ff6600',
                  marginBottom: '10px',
                }}>Our Offerings:</h4>
                <ul style={{
                  listStyleType: 'none',
                  padding: 0,
                  textAlign: 'left',
                }}>
                  {service.details.map((detail, i) => (
                    <li key={i} style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '8px',
                    }}>
                      <ChevronRight size={16} style={{ color: '#ff6600', marginRight: '8px' }} />
                      {detail}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default ServicesPage;