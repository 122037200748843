import React, { useEffect, useState } from "react";
import { Shield, Code, Lock, Globe, ChevronRight } from 'lucide-react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const GlowingButton = ({ children, className }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className={`relative group ${className}`}
  >
    <span className="absolute inset-0 bg-gradient-to-r from-orange-500 to-pink-500 rounded-md blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></span>
    <span className="relative block px-8 py-3 bg-gradient-to-r from-orange-600 to-pink-600 rounded-md text-white font-semibold">
      {children}
    </span>
  </motion.button>
);

const CyclingText = () => {
  const words = ["Cyber Security", "AI Defense", "Web Protection"];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="h-16 w-full relative overflow-hidden">
      {words.map((word, i) => (
        <motion.span
          key={word}
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: i === index ? 0 : 50, opacity: i === index ? 1 : 0 }}
          transition={{ duration: 0.5 }}
          className="absolute left-0 right-0 text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-pink-600"
        >
          {word}
        </motion.span>
      ))}
    </div>
  );
};

const FloatingIcon = ({ Icon, top, left, size, color, animationDelay }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start({
        y: [0, -20, 0],
        transition: { duration: 4, repeat: Infinity, delay: animationDelay }
      });
    }
  }, [controls, inView, animationDelay]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      className="absolute"
      style={{ top: `${top}%`, left: `${left}%` }}
    >
      <Icon size={size} color={color} />
    </motion.div>
  );
};

const ParallaxBackground = () => {
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="absolute inset-0 overflow-hidden">
      <motion.div
        className="absolute inset-0"
        style={{
          backgroundImage: "url('/api/placeholder/1920/1080')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          y: scrollY * 0.5
        }}
      />
      <div className="absolute inset-0 bg-black opacity-75" />
    </div>
  );
};

const FeatureCard = ({ title, description, icon: Icon }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg p-6 flex flex-col items-center"
  >
    <Icon className="text-orange-500 mb-4" size={40} />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-300 text-center">{description}</p>
  </motion.div>
);

const Navbar = () => (
  //<nav className="relative z-10 bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between h-16">
        <div className="flex items-center">
          <span className="text-white font-bold text-xl">CyberShield</span>
        </div>
        <div className="flex space-x-4">
          <a href="#" className="text-gray-300 hover:text-white">Home</a>
          <a href="#" className="text-gray-300 hover:text-white">Features</a>
          <a href="#" className="text-gray-300 hover:text-white">Pricing</a>
          <a href="#" className="text-gray-300 hover:text-white">Contact</a>
        </div>
      </div>
    </div>
  //</nav>
);

const Footer = () => (
  <footer className="relative z-10 bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg py-8">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-wrap justify-between items-center">
        <div className="w-full md:w-auto text-center md:text-left mb-4 md:mb-0">
          <span className="text-white font-bold text-xl">CyberShield</span>
          <p className="text-gray-300 mt-2">Securing your digital future</p>
        </div>
        <div className="w-full md:w-auto text-center md:text-right">
          <div className="flex justify-center md:justify-end space-x-6">
            <a href="#" className="text-gray-300 hover:text-white">
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
              </svg>
            </a>
            <a href="#" className="text-gray-300 hover:text-white">
              <span className="sr-only">Twitter</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
            <a href="#" className="text-gray-300 hover:text-white">
              <span className="sr-only">GitHub</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default function Index() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.remove('light');
    document.documentElement.classList.add('dark');
  }, []);

  return (
    <div className="min-h-screen relative overflow-hidden text-white">
      <ParallaxBackground />
      <Navbar />

      <div className="relative z-10">
        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 text-center relative">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-6xl font-bold mb-6 flex flex-col items-center"
          >
            <span className="text-white">We Safeguard Your</span>
            <CyclingText />
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-gray-300 max-w-2xl mx-auto mb-10 text-xl"
          >
            Empowering your digital fortress with next-gen AI and unrivaled expertise in cybersecurity.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <GlowingButton className="text-lg">
              Fortify Your Digital Assets <ChevronRight className="inline ml-2" />
            </GlowingButton>
          </motion.div>
        </main>

        <FloatingIcon Icon={Shield} top={10} left={10} size={32} color="#ff6600" animationDelay={0} />
        <FloatingIcon Icon={Code} top={20} left={80} size={32} color="#ff6600" animationDelay={1} />
        <FloatingIcon Icon={Lock} top={70} left={20} size={32} color="#ff6600" animationDelay={2} />
        <FloatingIcon Icon={Globe} top={60} left={80} size={32} color="#ff6600" animationDelay={3} />
      </div>

      <section className="relative md:py-24 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-4xl font-bold mb-12 text-center"
          >
            Our Cutting-Edge Features
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              title="AI-Powered Threat Detection"
              description="Leverage advanced machine learning algorithms to identify and neutralize threats in real-time."
              icon={Shield}
            />
            <FeatureCard
              title="Quantum-Resistant Encryption"
              description="Future-proof your data with encryption that withstands quantum computing attacks."
              icon={Lock}
            />
            <FeatureCard
              title="Global Threat Intelligence"
              description="Stay ahead of cyber criminals with our vast network of global threat sensors and analysts."
              icon={Globe}
            />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}