import React from "react";
import { Link } from "react-router-dom";
import aboutImg from "../assets/homepage/home1.jpeg";
import { FiCheckCircle, MdKeyboardArrowRight } from '../assets/icons/vander';

export default function AboutOne() {
    return (
        <>
            <style>
                {`
                    .text-darkOrange {
                        color: #ff6600 !important; /* Override Tailwind's text color */
                    }
                    .bg-customBlack {
                        background-color: #000000 !important; /* Override Tailwind's background color */
                    }
                    .bg-customWhite {
                        background-color: #FFFFFF !important; /* Override Tailwind's background color */
                    }
                `}
            </style>
            <div className="container relative md:mt-24 mt-16">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
                    <div className="relative overflow-hidden rounded-lg border border-black bg-gradient-to-tl to-orange-600/30 from-black/30 dark:to-orange-600/50 dark:from-black/50 ps-6 pt-6 lg:me-8">
                        <img src={aboutImg} className="ltr:rounded-tl-lg rtl:rounded-tr-lg" alt="" />
                    </div>

                    <div className="">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-darkOrange">
                        Cybersecurity Consulting,<br />  Awareness, and Training
                        </h3>
                        <p className="text-white max-w-xl">
                            "NexGen Gaurds provide Comprehensive cybersecurity consulting and customized training programs to: "
                        </p>

                        <ul className="list-none text-white mt-4">
                            <li className="mb-2 flex items-center">
                                <FiCheckCircle className="text-darkOrange h-5 w-5 me-2" />
                                Strengthen organizational security
                            </li>
                            <li className="mb-2 flex items-center">
                                <FiCheckCircle className="text-darkOrange h-5 w-5 me-2" />
                                Ensure compliance
                            </li>
                            <li className="mb-2 flex items-center">
                                <FiCheckCircle className="text-darkOrange h-5 w-5 me-2" />
                                Build a culture of security awareness
                            </li>
                        </ul>

                        <div className="mt-4">
                            <Link to="/aboutus" className="hover:text-darkOrange font-medium duration-500 inline-flex items-center">
                                Find Out More <MdKeyboardArrowRight className="ms-1 text-white" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
