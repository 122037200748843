import React from "react";
import { Link } from "react-router-dom";
import { color, motion } from "framer-motion";
import LogoLight from "../assets/logo/logo.png";
import {
 
  FiLinkedin,
  FiFacebook,
  FiInstagram,
  FiTwitter,
  FiMail,
  FiFileText,
} from "../assets/icons/vander";

const GlowingButton = ({ children, className }) => (
  <motion.button
    className={`relative group ${className}`}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <motion.span
      className="absolute inset-0 bg-orange-500 rounded-md blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"
      animate={{
        scale: [1, 1.2, 1],
        rotate: [0, 10, -10, 0],
      }}
      transition={{
        duration: 3,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    ></motion.span>
    <span className="relative block px-8 py-3 bg-gradient-to-r from-orange-500 to-orange-600 rounded-md text-white font-semibold">
      {children}
    </span>
  </motion.button>
);

const SocialIcon = ({ Icon, link }) => (
  <motion.li
    className="inline-block"
    whileHover={{ scale: 1.2 }}
    whileTap={{ scale: 0.9 }}
  >
    <Link
      to={link}
      target="_blank"
      className="h-10 w-10 inline-flex items-center justify-center tracking-wide align-middle duration-300 text-base text-center border border-gray-700 rounded-full hover:border-orange-500 hover:bg-orange-500 text-gray-300 hover:text-white transition-all"
    >
      <Icon className="h-4 w-4 align-middle" />
    </Link>
  </motion.li>
);

const FooterLink = ({ href, children }) => (
  <motion.li whileHover={{ x: 5 }} className="mb-2">
    <Link
      to={href}
      className="text-gray-300 hover:text-orange-500 transition-colors duration-300"
    >
      {children}
    </Link>
  </motion.li>
);

export default function Footer() {
  return (
    <footer className="bg-black text-white">
      <div className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >

            {/*

              <Link to="/" className="inline-block mb-6">
              <img src={LogoLight} alt="Logo" className="h-8" />
            </Link>

             */}
         
          
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
         
          <span
            style={{ fontSize: "2rem", fontWeight: "bold",  color: "#ff6600" }}
          >
            NEX<span style={{color:"white"}}>GEN</span> GUARDS
          </span>
        </div>
            <p className="text-gray-400 mb-6">
            Protecting Your Business, Securing Your Future.
            </p>
            <ul className="flex space-x-4">
              {[
                {
                  icon: FiLinkedin,
                  link: "https://pk.linkedin.com/in/bilalsaleem01",
                },
                {
                  icon: FiMail,
                  link: "211096@students.au.edu.pk",
                },
               
              ].map((item, idx) => (
                <SocialIcon key={idx} Icon={item.icon} link={item.link} />
              ))}
            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <h4 className="text-lg font-semibold mb-4">Company</h4>
            <ul>
              <FooterLink href="/aboutus">About Us</FooterLink>
              <FooterLink href="/pricing">Team</FooterLink>
              <FooterLink href="/services">Services</FooterLink>
              <FooterLink href="/researches">Researches</FooterLink>
              <FooterLink href="/blog">Blogs</FooterLink>
              <FooterLink href="/achievements">Achievements</FooterLink>
              <FooterLink href="/contact">Contact Us</FooterLink>

            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h4 className="text-lg font-semibold mb-4">Help Center</h4>
            <ul>
              <FooterLink href="/home">FAQ's</FooterLink>
              <FooterLink href="/contactus">Contact Us</FooterLink>
            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <h4 className="text-lg font-semibold mb-4">Subscribe</h4>
            <p className="text-gray-400 mb-4">
              Stay updated with our latest features and releases.
            </p>
            <div className="flex">
              <input
                type="email"
                placeholder="Enter your email"
                className="bg-gray-800 text-white px-4 py-2 rounded-l-md focus:outline-none focus:ring-2 focus:ring-orange-500 flex-grow"
              />
              <button className="bg-orange-500 text-white px-4 py-2 rounded-r-md hover:bg-orange-600 transition-colors duration-300">
                Subscribe
              </button>
            </div>
          </motion.div>
        </div>

        <motion.div
          className="mt-12 pt-8 border-t border-gray-800 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <p className="text-gray-400">
            © {new Date().getFullYear()} Unlock your safe digital world with NexGen Gaurds{" "}
            <motion.span
              className="text-orange-500"
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ duration: 1, repeat: Infinity }}
            >
              ♥
            </motion.span>{" "}
           
            
          </p>
        </motion.div>
      </div>

      {/*
      
      
      */}

      <motion.div
        className="bg-black py-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <div className="container mx-auto px-4 text-center">
          <p className="text-gray-400">
            Ready to get started? Join thousands of satisfied customers today!
          </p>
          <GlowingButton className="mt-4">Start Your Free Trial</GlowingButton>
        </div>
      </motion.div>
    </footer>
  );
}
