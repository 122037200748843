import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Shield, Menu, X } from "lucide-react";
import { color, motion } from "framer-motion";

const GlowingButton = ({ children, className }) => (
  <button className={`relative group ${className}`}>
    <span className="absolute inset-0 bg-orange-400 rounded-md blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></span>
    <span className="relative block px-8 py-3 bg-orange-500 rounded-md text-white font-semibold">
      {children}
    </span>
  </button>
);

const Navbar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    backgroundColor: `rgba(26, 26, 26, ${Math.min(scrollPosition / 300, 0.9)})`,
    transition: "background-color 0.3s ease-in-out",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backdropFilter: `blur(${Math.min(scrollPosition / 100, 5)}px)`,
  };

  const ToggleNavbarStyle = {
    backgroundColor: `rgba(26, 26, 26, ${Math.min(scrollPosition / 300, 0.9)})`,
    transition: "background-color 0.3s ease-in-out",
    width: "12rem",
    padding: "2rem",
    right: 0,
    position: "absolute",
    top: "4.4rem",
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
    backdropFilter: `blur(${Math.min(scrollPosition / 100, 5)}px)`,
  };

  const linkStyle = {
    color: "white",
    textDecoration: "none",
    transition: "color 0.3s ease-in-out",
  };

  const activeLinkStyle = {
    ...linkStyle,
    color: "#ff6600",
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div style={navbarStyle}>
      <nav
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          
          <span
            style={{ fontSize: "2rem", fontWeight: "bold", color: "#ff6600" }}
          >
            NEX<span style={{color:"white"}}>GEN</span> GUARDS      
          </span>
        </div>
        <div className="hidden md:flex">
          <div style={{ display: "flex", gap: "1.5rem", position: "relative" }}>
            {/* Dropdown Menu for Home */}
            <div
              style={{ position: "relative" }}
              onMouseEnter={toggleDropdown}
              onMouseLeave={toggleDropdown}
            >
              <Link to="/" style={linkStyle} activeStyle={activeLinkStyle}>
                Home
              </Link>
              {isDropdownOpen && (
                <div
                  className="absolute bg-gray-800 rounded-lg shadow-lg "
                  style={{ top: "100%", left: 0, zIndex: 1000 }}
                >
                  
                </div>
              )}
            </div>
            <Link to="/aboutus" style={linkStyle} activeStyle={activeLinkStyle}>
              About Us
            </Link>
            <Link to="/pricing" style={linkStyle} activeStyle={activeLinkStyle}>
              Team
            </Link>

            <Link
              to="/services"
              style={linkStyle}
              activeStyle={activeLinkStyle}
            >
              Services
            </Link>
            <Link to="/blog" style={linkStyle} activeStyle={activeLinkStyle}>
              Blog
            </Link>
            <Link
              to="/researches"
              style={linkStyle}
              activeStyle={activeLinkStyle}
            >
              Researches
            </Link>
            <Link
              to="/achievements"
              style={linkStyle}
              activeStyle={activeLinkStyle}
            >
              Achievements
            </Link>
            <Link to="/contact" style={linkStyle} activeStyle={activeLinkStyle}>
              Contact Us
            </Link>
          </div>
        </div>

        {/* mobile menubutton */}

        <div className="md:hidden">
          <button onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? (
              <X size={32} color="white" />
            ) : (
              <Menu size={32} color="white" />
            )}
          </button>
        </div>

        {isMobileMenuOpen && (
          <div className="pl-2 md:hidden" style={ToggleNavbarStyle}>
            <Link
              className=""
              to="/"
              style={linkStyle}
              activeStyle={activeLinkStyle}
            >
              Home
            </Link>
            <Link to="/aboutus" style={linkStyle} activeStyle={activeLinkStyle}>
              About Us
            </Link>
            <Link to="/pricing" style={linkStyle} activeStyle={activeLinkStyle}>
              Team
            </Link>
            <Link
              to="/services"
              style={linkStyle}
              activeStyle={activeLinkStyle}
            >
              Services
            </Link>
            <Link to="/blog" style={linkStyle} activeStyle={activeLinkStyle}>
              Blog
            </Link>
            <Link
              to="/researches"
              style={linkStyle}
              activeStyle={activeLinkStyle}
            >
              Researches
            </Link>
            <Link
              to="/achievements"
              style={linkStyle}
              activeStyle={activeLinkStyle}
            >
              Achievements
            </Link>
            <Link to="/contact" style={linkStyle} activeStyle={activeLinkStyle}>
              Contact Us
            </Link>
            <GlowingButton className="text-lg">Learn More</GlowingButton>
          </div>
        )}

<Link to="/contact">
  <GlowingButton className="text-lg hidden md:flex">
    Learn More
  </GlowingButton>
</Link>
      </nav>
    </div>
  );
};

export default Navbar;
