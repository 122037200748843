import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Footer from "../components/footer";
import NavLight from "../components/navlight";
import { blogData } from "../data/data";
import { FiClock, FiCalendar } from '../assets/icons/vander';
import Navbar from "../components/navbar";

export default function Blog() {
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
    }, []);

    return (
        <>
            <div className="dark-bg" style={{ color: "white" }}>
                <section className="relative md:py-44 py-32 bg-[url('../../assets/images/bg/aboutusbg.png')] bg-no-repeat bg-bottom bg-cover">
                    <div className="container relative">
                        <div className="grid grid-cols-1 text-center mt-6">
                            <div>
                                <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">Blogs</h5>
                            </div>
                            <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
                                <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                                    <Link to="/">NexGen Guards</Link>
                                </li>
                                <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                                    <i className="mdi mdi-chevron-right"></i>
                                </li>
                                <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white" aria-current="page">
                                    Blogs
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <div className="relative">
                    <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                        <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                <section className="relative md:py-24 py-16">
                    <div className="container relative">
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                            {blogData.map((item, index) => {
                                return (
                                    <div className="relative bg-white dark:bg-slate-900 p-4 rounded-md shadow dark:shadow-gray-700" key={index}>
                                        <img src={item.image} className="rounded-md shadow dark:shadow-gray-700" alt="" />
                                        <div className="pt-4">
                                            <div className="flex justify-between items-center">
                                                <div className="space-x-1">
                                                    <Link to="" className="bg-amber-400/10 text-amber-500 dark:text-amber-400 text-[12px] font-semibold px-2.5 py-0.5 rounded h-5">AI</Link>
                                                    <Link to="" className="bg-amber-400/10 text-amber-500 dark:text-amber-400 text-[12px] font-semibold px-2.5 py-0.5 rounded h-5">Marketing</Link>
                                                </div>
                                                <span className="flex items-center"><FiClock className="h-4 w-4" /><span className="ms-1 text-slate-400">5 min read</span></span>
                                            </div>
                                            <div className="mt-5">
                                                <Link to={item.client} className="text-lg font-semibold hover:text-amber-400">{item.title}</Link>
                                            </div>
                                            <div className="mt-5 flex justify-between items-center">
                                                <span className="flex items-center">
                                                    <Link to="" className="ms-1 text-slate-400 hover:text-amber-400">{item.author}</Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
}
