import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Footer from "../components/footer";
import NavLight from "../components/navlight";
import Navbar from "../components/navbar";
import { FiClock, FiCalendar } from '../assets/icons/vander';
import aboutusbg from "../assets/images/bg/aboutusbg.png";

const researchPapers = [
    {
        id: 1,
        title: "A Survey of Cybersecurity Laws, Regulations, and Policies in Technologically Advanced Nations: A Case Study of Pakistan to Bridge the Gap",
        link: "https://www.researchgate.net/publication/384115419_A_survey_of_cybersecurity_laws_regulations_and_policies_in_technologically_advanced_nations_a_case_study_of_Pakistan_to_bridge_the_gap?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ",
        author: "Author Name 1",
        date: "Date 1",
        image: aboutusbg, // Replace with actual image path
        caption: "Caption for Research Paper 1"
    },
    {
        id: 2,
        title: "A Survey of Bug Bounty Programs in Strengthening Cybersecurity and Privacy in the Blockchain Industry",
        link: "https://www.researchgate.net/publication/382126794_A_Survey_of_Bug_Bounty_Programs_in_Strengthening_Cybersecurity_and_Privacy_in_the_Blockchain_Industry?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ",
        author: "Author Name 2",
        date: "Date 2",
        image: aboutusbg, // Replace with actual image path
        caption: "Caption for Research Paper 2"
    },
    {
        id: 3,
        title: "DelSec: An Anti-Forensics Data Deletion Framework for Smartphones, IoT, and Edge Devices",
        link: "https://www.researchgate.net/publication/382107416_DelSec_An_Anti-Forensics_Data_Deletion_Framework_for_Smartphones_IoT_and_Edge_Devices?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ",
        author: "Author Name 3",
        date: "Date 3",
        image:aboutusbg, // Replace with actual image path
        caption: "Caption for Research Paper 3"
    },
    {
        id: 4,
        title: "Revolutionizing Data Center Networks: Dynamic Load Balancing via Floodlight in SDN Environment",
        link: "https://www.researchgate.net/publication/379158149_Revolutionizing_Data_Center_Networks_Dynamic_Load_Balancing_via_Floodlight_in_SDN_Environment?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ",
        author: "Author Name 4",
        date: "Date 4",
        image: aboutusbg, // Replace with actual image path
        caption: "Caption for Research Paper 4"
    },
    {
        id: 5,
        title: "A Survey of Electric Vehicle Driver-Assisting Smartphone Applications: Vulnerabilities, Risks, and Defensive Strategies",
        link: "https://www.researchgate.net/publication/379843308_A_Survey_of_Electric_Vehicle_Driver-Assisting_Smartphone_Applications_Vulnerabilities_Risks_and_Defensive_Strategies?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ",
        author: "Author Name 5",
        date: "Date 5",
        image: aboutusbg,// Replace with actual image path
        caption: "Caption for Research Paper 5"
    },
    {
        id: 6,
        title: "Smart Cities: A Novel Framework for Energy Production and Harvesting using Renewable Energy",
        link: "https://www.researchgate.net/publication/376427436_Smart_Cities_A_Novel_Framework_for_Energy_Production_and_Harvesting_using_Renewable_Energy?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ",
        author: "Author Name 6",
        date: "Date 6",
        image: aboutusbg,// Replace with actual image path
        caption: "Caption for Research Paper 6"
    },
    {
        id: 7,
        title: "A Cybersecurity Risk Assessment of Electric Vehicle Mobile Applications: Findings and Recommendations",
        link: "https://www.researchgate.net/publication/371239381_A_cybersecurity_risk_assessment_of_electric_vehicle_mobile_applications_findings_and_recommendations?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ",
        author: "Author Name 7",
        date: "Date 7",
        image: aboutusbg,// Replace with actual image path
        caption: "Caption for Research Paper 7"
    },
    {
        id: 8,
        title: "Emerging Cybersecurity and Privacy Threats to Electric Vehicles and Their Impact on Human and Environmental Sustainability",
        link: "https://www.researchgate.net/publication/367293870_Emerging_Cybersecurity_and_Privacy_Threats_to_Electric_Vehicles_and_Their_Impact_on_Human_and_Environmental_Sustainability?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ",
        author: "Author Name 8",
        date: "Date 8",
        image: aboutusbg, // Replace with actual image path
        caption: "Caption for Research Paper 8"
    },
];




export default function Research() {
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
    }, []);

    return (
        <>
            <div className="dark-bg" style={{ color: "white" }}>
                <section className="relative md:py-44 py-32 bg-[url('../../assets/images/bg/aboutusbg.png')] bg-no-repeat bg-bottom bg-cover">
                    <div className="container relative">
                        <div className="grid grid-cols-1 text-center mt-6">
                            <div>
                                <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">Research Papers</h5>
                            </div>
                            <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
                                <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                                    <Link to="/">NexGen Guards</Link>
                                </li>
                                <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                                    <i className="mdi mdi-chevron-right"></i>
                                </li>
                                <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white" aria-current="page">
                                    Research Papers
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <div className="relative">
                    <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                        <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>

                <section className="relative md:py-24 py-16">
                    <div className="container relative">
                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                            {researchPapers.map((item, index) => (
                                <div className="relative bg-white dark:bg-slate-900 p-4 rounded-md shadow dark:shadow-gray-700" key={index}>
                                    <img src={item.image} className="rounded-md shadow dark:shadow-gray-700" alt="" />
                                    <div className="pt-4">
                                        <div className="flex justify-between items-center">
                                            <div className="space-x-1">
                                                <Link to="" className="bg-amber-400/10 text-amber-500 dark:text-amber-400 text-[12px] font-semibold px-2.5 py-0.5 rounded h-5">AI</Link>
                                                <Link to="" className="bg-amber-400/10 text-amber-500 dark:text-amber-400 text-[12px] font-semibold px-2.5 py-0.5 rounded h-5">Data</Link>
                                            </div>
                                            <span className="text-slate-400 dark:text-slate-500 text-sm">{index + 1}/8</span>
                                        </div>
                                        <Link to={item.link} target="_blank" className="hover:text-amber-500 text-lg font-semibold ease-in-out duration-500 block mt-2">{item.title}</Link>
                                        <p className="text-slate-400 dark:text-slate-400 mt-2">{item.caption}</p>
                                    </div>
                                    <div className="p-4 mt-4 bg-slate-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-700">
                                        <div className="flex justify-between items-center">
                                            <span className="text-slate-400 dark:text-slate-400 flex items-center gap-2">
                                                <FiClock className="w-4 h-4" />
                                                {item.date}
                                            </span>
                                            <span className="text-slate-400 dark:text-slate-400 flex items-center gap-2">
                                                <FiCalendar className="w-4 h-4" />
                                                {item.author}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </>
    );
}
