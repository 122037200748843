import React, { useState, useEffect } from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import client1 from "../assets/team/bilal.png";
import client2 from "../assets/team/maila.png";
import client3 from "../assets/team/ali.png";
import client4 from "../assets/team/alishba.png";
import { motion } from 'framer-motion';

const Sparkles = ({ size, className, style }) => (
  <div
    className={`sparkle ${className}`}
    style={{
      width: size,
      height: size,
      borderRadius: '50%',
      backgroundColor: 'transparent',
      boxShadow: `0 0 ${size}px yellow`,
      position: 'absolute',
      ...style,
    }}
  />
);

const TeamMember = ({ name, title, description, imageSrc, index }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, index * 200);

    return () => clearTimeout(timer);
  }, [index]);

  return (
    <div
      className={`relative p-4 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-12'} 
      transition-all duration-700 ease-out`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="relative overflow-hidden rounded-xl bg-gradient-to-br from-gray-900 to-gray-800 
        shadow-xl transition-all duration-500 hover:scale-105 group">

        <div className="absolute inset-0 opacity-75 bg-[radial-gradient(circle_at_50%_50%,rgba(255,140,50,0.1),transparent_50%)]" />
        <div className={`absolute inset-0 bg-gradient-to-br from-orange-500/20 to-yellow-500/20 
          transition-opacity duration-500 ${isHovered ? 'opacity-100' : 'opacity-0'}`} />

        <div className="absolute top-0 left-0 w-8 h-8 border-t-2 border-l-2 border-orange-500/50 
          transition-all duration-500 group-hover:w-12 group-hover:h-12" />
        <div className="absolute bottom-0 right-0 w-8 h-8 border-b-2 border-r-2 border-yellow-500/50 
          transition-all duration-500 group-hover:w-12 group-hover:h-12" />

        <div className="relative aspect-square overflow-hidden">
          <img
            src={imageSrc}
            alt={`${name}'s profile`}
            className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent 
            opacity-60 group-hover:opacity-40 transition-opacity duration-500" />

          <div className={`absolute inset-0 transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0'}`}>
            {[...Array(4)].map((_, i) => (
              <Sparkles
                key={i}
                size={16}
                className={`absolute text-yellow-400 animate-floating-${i + 1}`}
                style={{
                  top: `${20 + i * 30}%`,
                  left: `${10 + i * 35}%`,
                  animationDelay: `${i * 0.5}s`
                }}
              />
            ))}
          </div>
        </div>

        <div className="relative p-6 text-center transform transition-transform duration-500 group-hover:translate-y-[-10px]">
          <h3 className="text-xl font-semibold text-white mb-2 group-hover:text-orange-400 transition-colors duration-300">{name}</h3>
          <p className="text-orange-400 mb-4 opacity-75 group-hover:opacity-100 transition-opacity duration-300">{title}</p>
          <p className="text-orange-400 mb-4 opacity-75 group-hover:opacity-100 transition-opacity duration-300">{description}</p>

          <div className="flex justify-center space-x-4">
            {[FaFacebookF, FaTwitter, FaLinkedinIn].map((Icon, i) => (
              <button
                key={i}
                className="p-2 rounded-full bg-gray-800/50 hover:bg-orange-600 
                  transition-all duration-300 hover:scale-110 hover:rotate-6 group/icon"
              >
                <Icon size={20} className="text-white transition-transform duration-300 group-hover/icon:scale-110" />
                <span className="absolute inset-0 rounded-full border border-orange-400/50 scale-0 group-hover/icon:scale-100 transition-transform duration-300" />
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const EnhancedTeamMembersHeading = () => {
  const letters = "Team Members".split("");

  return (
    <div className="text-center mb-20 relative">
      <div className="relative inline-block">
        {letters.map((letter, index) => (
          <motion.span
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.5,
              delay: index * 0.1,
              ease: [0.6, -0.05, 0.01, 0.99]
            }}
            className="text-6xl font-extrabold inline-block"
          >
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 via-yellow-500 to-red-500">
              {letter === " " ? "\u00A0" : letter}
            </span>
          </motion.span>
        ))}
      </div>

      <motion.div
        initial={{ width: 0 }}
        animate={{ width: "80%" }}
        transition={{ duration: 1, delay: 1.5, ease: "easeInOut" }}
        className="absolute left-1/2 transform -translate-x-1/2 -bottom-4 h-1 rounded-full bg-gradient-to-r from-orange-500 via-yellow-500 to-red-500"
      />

      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, delay: 2 }}
        className="absolute -top-12 -left-12 w-24 h-24 bg-orange-500 rounded-full opacity-20 blur-xl"
      />

      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, delay: 2.2 }}
        className="absolute -bottom-8 -right-8 w-20 h-20 bg-yellow-500 rounded-full opacity-20 blur-xl"
      />

      <svg className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full -z-10" viewBox="0 0 200 200">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f59e0b" />
            <stop offset="100%" stopColor="#ef4444" />
          </linearGradient>
        </defs>
        <motion.path
          d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
          fill="none"
          stroke="url(#gradient)"
          strokeWidth="0.5"
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{ pathLength: 1, opacity: 1 }}
          transition={{ duration: 2, delay: 0.5, ease: "easeInOut" }}
        />
      </svg>
    </div>
  );
};

const Teams = () => {
  const teamData = [
    { name: "Bilal Saleem", title: "C.E.O NexGen Gaurds", description:"Penetration Tester | Cyber Security Researcher | Ethical Hacker", imageSrc: client1 },
    { name: "Maila Zahra", title: "CVM and CoFounder",description:"Network Security Analyst | Cybersecurity Researcher", imageSrc: client2 },
    { name: "Muhammad Ali Hassan", title: "CMO NexGen Gaurds", description:"Microsoft Learn Student Ambassador @MLSA AU", imageSrc: client3 },
    { name: "Alishba Rehman", title: "CSM NexGen Gaurds", description:"Co-Lead Research Club @AUCSS | Writer", imageSrc: client4 },
  ];

  return (
    <div className="min-h-screen relative overflow-hidden text-white" style={{background: 'linear-gradient(to bottom right, #000000, #000000)'}}>
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(255,140,50,0.1),transparent_50%)] animate-pulse-slow" />

      <div className="max-w-6xl mx-auto px-4 relative">
        <EnhancedTeamMembersHeading />

        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-4 relative">
            {["Our", "Professional", "Team"].map((word, i) => (
              <span key={i} className={`inline-block animate-fade-in-up opacity-0`} style={{ animationDelay: `${i * 200}ms`, animationFillMode: 'forwards' }}>
                {word}{' '}
              </span>
            ))}
          </h2>
          <div className="h-1 w-20 mx-auto rounded-full bg-gradient-to-r from-orange-500 to-yellow-500 
            animate-expand-width" />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {teamData.map((member, index) => (
            <TeamMember key={index} {...member} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Teams;