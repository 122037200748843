import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import { Shield, Code, Lock, Globe, ChevronDown } from 'lucide-react';
import { Link } from "react-router-dom";
import AchievementsSection from "../components/achievementSection";

import Navbar from "../components/navbar";
import BrandLogo from "../components/brandLogo";
import Features from "../components/features";
import AboutOne from "../components/aboutOne";
import AboutTwo from "../components/aboutTwo";
import AmazingFeatures from "../components/amazingFeatures";
import AboutThree from "../components/aboutThree";
import Pricing from "../components/pricing";
import Faq from "../components/Faq";
import Blogs from "../components/blogs";
import Footer from "../components/footer";

// ... (other imports remain the same)

const GlowingButton = ({ children, className }) => (
  <motion.button
    className={`relative group ${className}`}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <motion.span
      className="absolute inset-0 bg-orange-500 rounded-md blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"
      animate={{
        scale: [1, 1.2, 1],
        rotate: [0, 10, -10, 0],
      }}
      transition={{
        duration: 3,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    ></motion.span>
    <span className="relative block px-8 py-3 bg-gradient-to-r from-orange-500 to-orange-600 rounded-md text-white font-semibold">
      {children}
    </span>
  </motion.button>
);

const CyclingText = () => {
  const words = ["Cyber Website", "AI", "Web Development"];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="h-16 w-full relative overflow-hidden">
      <AnimatePresence>
        <motion.span
          key={words[index]}
          className="absolute left-0 right-0 text-5xl font-bold"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <span className="bg-gradient-to-r from-orange-400 to-orange-600 text-transparent bg-clip-text">
            {words[index]}
          </span>
        </motion.span>
      </AnimatePresence>
    </div>
  );
};

const FloatingIcon = ({ Icon, top, left, size, color }) => (
  <motion.div
    className="absolute"
    style={{ top: `${top}%`, left: `${left}%` }}
    animate={{
      y: [0, 15, 0],
      rotate: [0, 10, -10, 0],
      scale: [1, 1.2, 1],
    }}
    transition={{
      duration: 5,
      repeat: Infinity,
      repeatType: "reverse",
    }}
  >
    <Icon size={size} color={color} />
  </motion.div>
);

const ScrollDownIndicator = () => (
  <motion.div
    className="absolute bottom-10 left-1/2 transform -translate-x-1/2 cursor-pointer"
    animate={{
      y: [0, 10, 0],
    }}
    transition={{
      duration: 1.5,
      repeat: Infinity,
      repeatType: "reverse",
    }}
    onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}
  >
    <ChevronDown size={32} color="#ff6600" />
  </motion.div>
);

const ParticleBackground = () => {
  const particles = Array.from({ length: 50 }).map((_, i) => (
    <motion.div
      key={i}
      className="absolute w-2 h-2 bg-orange-500 rounded-full"
      style={{
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
      }}
      animate={{
        y: [0, Math.random() * 100 - 50],
        x: [0, Math.random() * 100 - 50],
        opacity: [0, 1, 0],
      }}
      transition={{
        duration: Math.random() * 10 + 10,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    />
  ));

  return <div className="absolute inset-0 overflow-hidden">{particles}</div>;
};

export default function Index() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.remove('light');
    document.documentElement.classList.add('dark-bg');
  }, []);

  return (
    <div className="min-h-screen relative overflow-hidden text-white" style={{background: 'linear-gradient(to bottom right, #000000,#000000)'}}>
      <ParticleBackground />
      
      <Navbar />

      <div className="relative z-10">
        <div className="mt-40 relative">
          <motion.div
            className="absolute inset-0 flex items-center justify-center"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.5, 0.7, 0.5],
            }}
            transition={{
              duration: 5,
              repeat: Infinity,
              repeatType: "reverse",
            }}
          >
            <div className="w-96 h-96 bg-orange-500 rounded-full filter blur-3xl opacity-10"></div>
          </motion.div>
          <FloatingIcon Icon={Shield} top={10} left={10} size={32} color="#ff6600" />
          <FloatingIcon Icon={Code} top={20} left={80} size={32} color="#ff6600" />
          <FloatingIcon Icon={Lock} top={70} left={20} size={32} color="#ff6600" />
          <FloatingIcon Icon={Globe} top={60} left={80} size={32} color="#ff6600" />
          <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 text-center relative">
            <motion.h1
              className="text-6xl font-bold mb-6 flex flex-col items-center"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <span className="text-white">We Secure Your</span>
              <CyclingText />
            </motion.h1>
            <motion.p
              className="text-gray-300 max-w-2xl mx-auto mb-10 text-xl"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Empowering your digital presence with state-of-the-art security and unmatched expertise.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <GlowingButton className="text-lg">
                Secure Your Future
              </GlowingButton>
            </motion.div>
          </main>
          <ScrollDownIndicator />
        </div>
      </div>

      <main>
        <section className="relative md:py-24 py-16">
          <Features classlist="container relative"/>
          <AboutOne />
          <AboutTwo />
          <AmazingFeatures />
          <AboutThree />

         <AchievementsSection/>

          <Faq />
          <Blogs />
        </section>
      </main>

      <Footer />
    </div>
  );
}
