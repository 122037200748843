import React from "react";
import { Link } from "react-router-dom";
import { blogData } from "../data/data";
import { FiClock, FiCalendar } from '../assets/icons/vander';

export default function Blogs() {
    return (
        <>
            <style>
                {`
                    .bg-dark {
                        background-color: transparent; /* Change from black to transparent */
                    }
                    .text-white {
                        color: #ffffff; /* White text color */
                    }
                    .text-accent-orange {
                        color: #ff6600; /* Orange color for accents */
                    }
                    .blog-card {
                        background-color: #1F1F1F; /* Dark background for blog cards */
                        padding: 20px;
                        border-radius: 8px;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                        transition: transform 0.3s, box-shadow 0.3s;
                    }
                    .blog-card:hover {
                        transform: translateY(-10px); /* Card hover effect */
                        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
                    }
                    .blog-title {
                        color: #ff6600; /* Orange for blog titles */
                        font-size: 1.25rem;
                        font-weight: bold;
                    }
                    .blog-category {
                        background-color: rgba(255, 191, 0, 0.1); /* Light amber for categories */
                        color: #ff6600; /* Accent orange for categories */
                        padding: 2px 8px;
                        font-size: 0.75rem;
                        border-radius: 4px;
                    }
                    .blog-meta {
                        color: #cccccc; /* Light gray for metadata */
                    }
                    .blog-date, .blog-read-time {
                        color: #ffbf00; /* Amber for date and read time */
                    }
                `}
            </style>

            <div className="container relative md:mt-24 mt-16 bg-dark text-white p-10">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-accent-orange">Latest News</h3>
                    <p className="text-slate-400 max-w-xl mx-auto">Artificial intelligence makes it fast easy to create content for your blog, social media, website, and more!</p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    {blogData.slice(0, 3).map((item, index) => {
                        return (
                            <div className="relative blog-card" key={index}>

                                
                                <img src={item.image} className="rounded-md shadow dark:shadow-gray-700" alt="" />
                                
                                <div className="pt-4">
                                    <div className="flex justify-between items-center">
                                        <div className="space-x-1">
                                            <Link to="" className="blog-category">AI</Link>
                                            <Link to="" className="blog-category">Marketing</Link>
                                        </div>

                                        <span className="flex items-center blog-meta"><FiClock className="h-4 w-4"/><span className="ms-1 blog-read-time">5 min read</span></span>
                                    </div>

                                    <div className="mt-5">
                                        <Link to="https://link.springer.com/article/10.1365/s43439-024-00128-y" className="blog-title hover:text-amber-400">{item.title}</Link>
                                    </div>

                                    <div className="mt-5 flex justify-between items-center">
                                        <span className="flex items-center">
                                            {/*   <img src={item.client} className="h-7 w-7 rounded-full" alt="" /> */}
                                           
                                            <Link to="https://link.springer.com/article/10.1365/s43439-024-00128-y" className="ms-1 text-slate-400 hover:text-amber-400">{item.author}</Link>
                                        </span>

                                        <span className="flex items-center blog-meta"><FiCalendar className="h-4 w-4"/><span className="ms-1 blog-date">{item.date}</span></span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
