import React, { useState, useEffect } from "react";
import about1 from '../assets/homepage/card1.jpeg';
import about2 from '../assets/homepage/card2.jpeg';
import about3 from '../assets/homepage/card3.jpeg';

export default function Features({ classlist }) {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setAnimate(true);
    }, []);

    const featuresData = [
        {
            image: about1,
            title: "AI-Powered Efficiency in Web Development",
            desc: " At NEXGEN Guards, our innovative solutions can reduce development time and improve site performance, making your digital presence stronger and more dynamic."
        },
        {
            image: about2,
            title: "40% More Secure Digital Environment",
            desc: "With advanced cybersecurity measures, NEXGEN Guards helps you safeguard critical assets and sensitive data. Our AI-backed threat detection and response protocols increase your protection by 40%, ensuring your business operates securely in a digital world."
        },
        {
            image: about3,
            title: " 5x Improved Customer Engagement",
            desc: "Harness the power of AI to understand your customers and build loyalty. NEXGEN Guards’ AI solutions analyze customer data to help you tailor experiences and boost retention."
        },
    ];

    return (
        <>
            <style jsx>{`
                .features-container {
                    background-color: transparent; /* Change from black to transparent */
                    color: white;
                    transition: all 0.3s ease;
                }
                .features-card {
                    background-color: #1a1a1a;
                    border: 1px solid #333;
                    border-radius: 8px;
                    overflow: hidden;
                    transition: all 0.3s ease;
                    box-shadow: 0 0 10px rgba(255, 102, 0, 0.1);
                }
                .features-card:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 0 20px rgba(255, 102, 0, 0.3);
                }
                .features-title {
                    color: #ff6600;
                    font-size: 1.25rem;
                    font-weight: 600;
                    transition: all 0.3s ease;
                }
                .features-card:hover .features-title {
                    text-shadow: 0 0 8px rgba(255, 102, 0, 0.5);
                }
                .features-desc {
                    color: #cccccc;
                }
                .features-highlight {
                    color: #ff6600;
                    text-shadow: 0 0 10px rgba(255, 102, 0, 0.5);
                }
                .animate-fade-in {
                    opacity: 0;
                    transform: translateY(20px);
                    transition: opacity 0.5s ease, transform 0.5s ease;
                }
                .animate-fade-in.active {
                    opacity: 1;
                    transform: translateY(0);
                }
            `}</style>
            <div className={`${classlist} features-container`}>
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                        AI + WebDevelopment + Cybersecurity = <br /> outstanding <span className="features-highlight">NexGen gaurds</span>
                    </h3>

                    <p className="features-desc max-w-xl mx-auto">
                        We would make your 
                    </p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    {featuresData.map((item, index) => {
                        return (
                            <div className={`features-card animate-fade-in ${animate ? 'active' : ''}`}
                                style={{ transitionDelay: `${index * 0.1}s` }}
                                key={index}>
                                <div className="p-6 pb-0">
                                    <img src={item.image} className="rounded-t-md shadow-md w-full" alt="" />
                                </div>

                                <div className="p-6">
                                    <h5 className="features-title">{item.title}</h5>
                                    <p className="features-desc mt-3">{item.desc}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
