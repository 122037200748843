import React, { useState, useEffect, useRef } from 'react';
import { MessageCircle, X, Send, Sun, Moon, Zap } from 'lucide-react';
import axios from 'axios';

const API_KEY = "AIzaSyB4sFnRfvnLN9UXShb9UNZGG3s7zIyFn3E";

const ChatBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { text: "Hello, I'm the AI Assistant! How can I help you today?", sender: 'AI' }
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const messagesEndRef = useRef(null);

  const toggleChat = () => setIsOpen(!isOpen);
  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);

  const handleSend = async () => {
    if (inputMessage.trim()) {
      const newMessage = { text: inputMessage, sender: 'user' };
      const newMessages = [...messages, newMessage];
      setMessages(newMessages);
      setInputMessage('');
      setIsTyping(true);
      await processMessageToGemini(newMessages, inputMessage);
    }
  };

  const processMessageToGemini = async (chatMessages, userMessage) => {
    // Define keywords related to NexGen Guards
    const nexgenKeywords = [
        // Greetings
        'hello', 'hi', 'hey', 'good morning', 'good afternoon', 'good evening', 'good night', 'greetings', 'howdy', 'what’s up', 'sup', 'yo',
      
        // General Tech Words
        'technology', 'innovation', 'development', 'software', 'hardware', 'programming', 'coding', 'algorithm', 'data', 'database', 'cloud', 'computing', 'AI', 'artificial intelligence', 'machine learning', 'deep learning', 'neural network', 'robotics', 'automation', 'IoT', 'internet of things', 'blockchain', 'virtual reality', 'augmented reality', 'VR', 'AR', 'big data', 'analytics', 'quantum computing', '5G', 'digital transformation',
      
        // Web Development
        'web development', 'frontend', 'backend', 'full stack', 'HTML', 'CSS', 'JavaScript', 'React', 'Angular', 'Vue', 'Node.js', 'Express.js', 'Django', 'Flask', 'Ruby on Rails', 'ASP.NET', 'PHP', 'WordPress', 'API', 'REST', 'GraphQL', 'WebSocket', 'AJAX', 'JSON', 'XML',
      
        // Cybersecurity
        'cybersecurity', 'information security', 'network security', 'encryption', 'decryption', 'firewall', 'VPN', 'virus', 'malware', 'spyware', 'ransomware', 'phishing', 'trojan', 'worm', 'DDoS', 'denial of service', 'intrusion detection', 'IDS', 'IPS', 'penetration testing', 'ethical hacking', 'vulnerability', 'threat', 'risk', 'compliance', 'data breach', 'incident response', 'forensics', 'security audit', 'SOC', 'SIEM',
      
        // NexGen Guards Specific
        'nexgen', 'guards', 'services', 'solutions', 'performance', 'muhammad bilal', 'maila', 'ali hassan', 'fahim ahmed khan', 'ayesha tehreem', 'tayyaba anum', 'tdf hackathon', 'research papers', 'magazines', 'blog', 'researches', 'achievement',
      
        // Additional Tech & Cybersecurity Terms
        'authentication', 'authorization', 'biometrics', 'password', 'multi-factor authentication', 'MFA', 'security token', 'public key', 'private key', 'PKI', 'SSL', 'TLS', 'certificate', 'security policy', 'access control', 'identity management', 'IAM', 'SAML', 'OAuth', 'OpenID', 'SaaS', 'PaaS', 'IaaS', 'DevOps', 'SecOps', 'containerization', 'Docker', 'Kubernetes', 'microservices', 'serverless', 'CI/CD', 'version control', 'Git', 'GitHub', 'Bitbucket', 'GitLab',
      
        // More General Tech Words
        'code', 'development', 'software engineering', 'computer science', 'IT', 'information technology', 'systems', 'networks', 'protocols', 'Linux', 'Windows', 'macOS', 'Unix', 'virtualization', 'VMware', 'Hyper-V', 'Citrix', 'database management', 'SQL', 'NoSQL', 'MySQL', 'PostgreSQL', 'MongoDB', 'Cassandra', 'Redis', 'Oracle', 'SQL Server', 'data warehouse', 'ETL', 'data mining', 'data science', 'data engineer', 'data analyst', 'business intelligence', 'BI', 'reporting', 'dashboard', 'KPIs', 'performance metrics',
      
        // Cloud Providers
        'AWS', 'Amazon Web Services', 'Azure', 'Microsoft Azure', 'GCP', 'Google Cloud Platform', 'IBM Cloud', 'Oracle Cloud', 'Alibaba Cloud',
      
        // Programming Languages
        'Python', 'Java', 'C++', 'C#', 'Ruby', 'Go', 'Rust', 'Swift', 'Kotlin', 'Scala', 'Perl', 'PHP', 'JavaScript', 'TypeScript', 'R', 'MATLAB', 'Bash', 'Shell',
      
        // Development Tools
        'IDE', 'integrated development environment', 'VS Code', 'Visual Studio', 'Eclipse', 'IntelliJ', 'PyCharm', 'NetBeans', 'Atom', 'Sublime Text', 'Notepad++', 'Xcode', 'Android Studio', 'Jupyter', 'Notebooks', 'Docker', 'Kubernetes', 'Ansible', 'Terraform', 'Puppet', 'Chef', 'Jenkins', 'Travis CI', 'CircleCI', 'GitHub Actions', 'GitLab CI', 'Bamboo',
      
        // More Cybersecurity Terms
        'CISO', 'chief information security officer', 'security analyst', 'security engineer', 'SOC analyst', 'threat hunter', 'threat intelligence', 'zero-day', 'exploit', 'payload', 'buffer overflow', 'cross-site scripting', 'XSS', 'SQL injection', 'command injection', 'directory traversal', 'cryptography', 'hashing', 'SHA', 'MD5', 'AES', 'RSA', 'ECC', 'steganography', 'data leakage', 'insider threat', 'BYOD', 'bring your own device', 'endpoint protection', 'EPP', 'EDR', 'XDR', 'mobile security', 'application security', 'web security', 'email security', 'cloud security', 'security awareness', 'phishing simulation', 'cyber threat intelligence', 'CTI', 'red team', 'blue team', 'purple team', 'bug bounty', 'vulnerability management', 'CVE', 'CVSS', 'OWASP', 'NIST', 'ISO 27001', 'GDPR', 'HIPAA', 'PCI DSS', 'SOX'
      ];
      

      const otherquestion=[
        {
          question: "When and where is NexGen Guards made?",
          answer:"Estd: May 18, 2024 Located:  FMC 5th Floor Business Incubation Center Air University Islamabad, Pakistan",
        },
        {
          question: "What are the domains of expertise of NexGen guards?",
          answer:"Below are our areas of specialization: Cybersecurity: We provide tailored consulting services to diagnose specific client vulnerabilities, conduct engaging assessments to determine what weaknesses exist, deploy penetration tests as a means of recreating real-world scenarios, and implement advanced threat detection with the help of advanced technologies.Artificial Intelligence: Among-st the challenges that this work encompasses are AIs for client interactions in the form of chat bots as well as data analytics applications. Our focus is on maximizing operational efficiency and optimizing decisions through automation and forecasting tools. Web Development: We design robust, flexible and easy to use web applications. The aspects that the process of development addresses include best security practices which also means developing strong coding practices and deploying efficient data protection mechanisms in order to protect the users’ information.",
        },
        {
          question: "What ways you use to illustrate your understanding in cybersecurity, web development, and AI?",
          answer:"Our expertise is demonstrated through: Cybersecurity: We perform risk assessments followed by risk mitigating plans and audits. We undertake the newest cybersecurity measures and undertake certifications so that our team is not out of date. Web Development: Our projects demonstrate a wide array of portfolios including the use of secure coding which is user centered. We conduct usability tests on applications and take in user feedback about our applications. Artificial Intelligence: We prove our prominence in AI field by demonstrating our clients how our AI applications enabled them and providing case studies. We are also able to demonstrate that we are active contributors in R&D practices which assist us to provide AI thought leadership.",
        },
        {
          question: "How do you implement these solutions?",
          answer:"Implementation involves a structured approach:Assessment: We start with an in-depth analysis of the client’s current systems and needs. Design: We develop a detailed plan that includes timelines, resources, and technology stacks. Development: Utilizing agile methodologies, we create solutions in iterative phases, allowing for regular feedback and adjustments. Testing: Rigorous testing protocols are applied to ensure functionality, security, and performance. Deployment: We oversee the deployment process, ensuring minimal disruption to the client’s operations. Maintenance and Support: Post-deployment, we offer ongoing support and updates to adapt to changing needs and emerging threats. ",
        },
        {
          question: "How would you go about setting up a chat bot for my website?",
          answer:"We prepare a chat bot in a step by step approach; We analyze the specific requirements of your business, its audience, and the questions expected to be asked.Then, we create the dialogue and interface design in the necessary brand style based on the analysis.Our developers create a chat bot based on AI technologies that can learn. We integrate the chat bot into the customers’ website, making sure that it works effectively with the other modules available.User testing is also applied before the official launch to perfect the pattern and other aspects of the algorithm and watch how people use the tool and incorporate the feedback we obtain when optimizing the texts and suggesting scenarios. ",
        },
        {
          question: "What are the company’s strategic goals?",
          answer:"Our company’s goals are as follows :Becoming the Industry Leader: Our goal is to be among those companies who are recognized for the good services and solutions that change the market starting from its requirements. Combating new challenges: We allocate funds to research and development, so that our approaches are relevant and able to face new challenges and exploit new opportunities. Increase Customer’s trust: We aim at establishing a lasting relationship with the customers through consistent and quality services which meet the individual requirements of the customers. Good relations: we believe target training and development of our team will create interactive and innovative environment capable of attracting the best people in the industry. Focus on industry expansion: Targeting specific industries and regions enables us to increase our presence and market share and increase awareness of ourselves.",
        },
        {
          question: "How will you achieve these goals?",
          answer:"We achieve our goals by maintaining exceptional service delivery through high-quality standards and continuous client feedback, ensuring we refine our processes effectively. We invest in innovation by allocating resources to research and development, exploring new technologies, and staying updated on industry trends to anticipate client needs. Engaging clients through regular communication channels allows us to gather insights and adapt our services accordingly. We prioritize professional development by providing our team with training programs, certifications, and access to conferences, enhancing their skills and knowledge. Additionally, we form strategic partnerships with key players in technology and cybersecurity, broadening our service offerings and expanding our market reach.",
        },
        {
          question: "How can you address emerging threats?",
          answer:" We proactively address emerging threats through a combination of real-time monitoring and threat intelligence. Our advanced threat detection systems continuously monitor network traffic and user behavior for abnormal activities, allowing us to identify potential threats before they escalate. Additionally, we stay informed about the latest vulnerabilities by being up-to-date and conducting ongoing cybersecurity research. This dual approach enables us to respond swiftly and effectively to emerging threats, ensuring the protection of our clients' digital assets.",
        },
        {
          question: "Can you explain your cybersecurity solutions?",
          answer:"Our cybersecurity solutions are designed to protect your digital assets through a variety of tailored services. First, we offer personalized consulting, where we collaborate with you to identify your specific risks and create customized security strategies that fit your needs. Next, we perform thorough vulnerability assessments to scan your systems and pinpoint any weaknesses that could be exploited. We also conduct penetration testing, where our team simulates cyber attacks to evaluate how well your security measures hold up, providing you with valuable insights for improvement. Finally, we implement advanced threat detection technologies that monitor your systems in real-time, allowing us to quickly identify and respond to potential threats, helping to minimize any damage",
        },
        {
          question: "How do you conduct vulnerability assessments?",
          answer:"We conduct vulnerability assessments by: Initial Scoping: Defining the scope of the assessment, including systems and networks to be evaluated. Automated Scanning: Utilizing industry-leading tools to perform automated scans that identify potential vulnerabilities. Manual Testing: Our experts conduct manual tests to identify complex vulnerabilities that automated tools may miss. Reporting: We provide detailed reports outlining findings, severity levels, and actionable recommendations for remediation.",
        },
        {
          question: "What AI application do you develop?",
          answer:"We develop a range of AI applications that can significantly enhance business operations. One of our key offerings is customer support chat bots, which are designed to assist with inquiries, provide information, and resolve issues in real-time, ultimately improving customer engagement and satisfaction. We also create data analytics tools that sift through large datasets to uncover trends and patterns, delivering actionable insights that inform important business decisions. Additionally, our predictive analytics solutions use historical data to forecast future trends and behaviors, empowering businesses to make proactive choices and stay ahead of the curve.",
        },
        {
          question: "How do you ensure the security of web applications, and what programming practices do you follow for secure coding?",
          answer:"To ensure the security of our web applications, we follow a comprehensive approach that includes both secure coding practices and ongoing assessments. We start by implementing secure coding practices, such as validating all inputs to prevent injection attacks and handling errors properly to avoid exposing sensitive information. Regular code reviews help us catch potential vulnerabilities and maintain adherence to security standards. Additionally, we conduct thorough security audits of our web applications to identify any flaws and ensure compliance with industry best practices. We also employ Web Application Firewalls (WAFs) to monitor and filter HTTP traffic, providing an extra layer of protection against attacks. Finally, we believe in the importance of user education; we provide clients with resources and training to help their teams adopt safe practices, creating a culture of security awareness that extends beyond our development effort ",
        },
        {
          question: "How can I get started with your services, and what is your process for customizing solutions?",
          answer:"Getting started is easy! First, reach out through our website or contact us to express your interest. We’ll set up an initial consultation to discuss your specific needs and goals. After that, we’ll create a tailored proposal based on our conversation. Once you agree, we’ll kick off the on-boarding process to get things moving. For customizing solutions, we engage in a thorough process. We start with an initial consultation to gather your requirements and understand your unique challenges. Then, we analyze your needs and existing systems, followed by designing a solution that aligns with your goals. Throughout development, we keep you involved for feedback and adjustments. Finally, we implement the solution and conduct a review session to ensure everything meets your expectations.",
        },
        {
          question: "How do you handle data protection and privacy?",
          answer:"We take data protection seriously. We implement strong encryption,for all sensitive data, to prevent unauthorized access, Our approach includes data minimization, collecting only what’s necessary, and conducting regular audits to ensure compliance and improve our practices.",
        },
        {
          question: "Can you share success stories or what distinguishes NexGen Guard from other service providers?",
          answer:"Absolutely! We have numerous success stories that highlight how our services have helped clients enhance security, improve operational efficiency through AI, and boost user engagement with successful web development projects. We’d be happy to provide detailed case studies upon request. What sets NexGen Guard apart is our holistic approach—we integrate cybersecurity, AI, and web development into comprehensive solutions. Our client-centric focus ensures we understand and respond to your needs, fostering long-lasting partnerships. Plus, we’re committed to continuous improvement, always refining our services to stay ahead of industry trends.",
        },
        {
          question: "When and where is NexGen Guards made?",
          answer:"Estd: May 18, 2024 Located:  FMC 5th Floor Business Incubation Center Air University Islamabad, Pakistan",
        },
      ]
    // Check if the message contains NexGen Guards-specific keywords
    const isNexGenQuestion = nexgenKeywords.some(keyword => 
      userMessage.toLowerCase().includes(keyword)
    );

    if (isNexGenQuestion) {
      // If it's a NexGen Guards-related question, use Gemini API to process it
      const data = {
        contents: [
          {
            parts: [
              { 
                text: `You are an AI assistant for NexGen Guards, a company revolutionizing the way we approach technology by combining AI with top-notch services. NexGen Guards focuses on web development, cyber security, and AI integration. The company is led by CEO Muhammad Bilal, Co-Founder Maila, and CMO Ali Hassan. Core team members include Web Developers Fahim Ahmed Khan, Ayesha Tehreem, and Tayyaba Anum. NexGen Guards achieved 55th position worldwide and 6th in Pakistan in the TDF Hackathon. The company also has multiple research papers and magazines available on their website. Here's a user query: "${userMessage}"` 
              }
            ]
          }
        ]
      };

      try {
        const response = await axios.post(
          `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent?key=${API_KEY}`,
          data,
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );

        const generatedContent = response.data?.candidates?.[0]?.content?.parts?.[0]?.text || "No response received.";

        setMessages([...chatMessages, {
          text: generatedContent,
          sender: "AI"
        }]);
      } catch (error) {
        console.error("Error with Gemini API request:", error);
        setMessages([...chatMessages, {
          text: "Error with Gemini API request. Please try again later.",
          sender: "AI"
        }]);
      } finally {
        setIsTyping(false);
      }
    } 
    else{
    const matchedQuestion = otherquestion.find(item => item.question.toLowerCase() === userMessage.toLowerCase());
  
    if (matchedQuestion) {
      // Respond with the matching answer
      setMessages([...chatMessages, {
        text: matchedQuestion.answer,
        sender: 'AI'
      }]);
    } else {
      // Fallback for unmatched queries
      setMessages([...chatMessages, {
        text: 'For more information, please visit our contact page: http://localhost:5173/contact',
        sender: 'AI'
      }]);
    }
  
    setIsTyping(false);
  
  };
  };
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const themeClass = isDarkMode ? 'dark' : 'light';

  return (
    <div className={`fixed bottom-4 right-4 font-sans ${themeClass}`}>
      {isOpen ? (
        <div className="w-96 h-[32rem] bg-gradient-to-br from-purple-600 to-blue-500 dark:from-black dark:to-black rounded-lg shadow-2xl overflow-hidden flex flex-col animate-slideUp">
          <div className="bg-gradient-to-r from-orange-500 to-orange-600 text-white p-4 flex justify-between items-center">
            <h2 className="text-xl font-bold">AI Assistant</h2>
            <div className="flex items-center space-x-2">
              <button onClick={toggleDarkMode} className="text-white hover:text-yellow-300 transition-colors">
                {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
              </button>
              <button onClick={toggleChat} className="text-white hover:text-gray-200 transition-colors">
                <X size={24} />
              </button>
            </div>
          </div>
          <div className="flex-grow overflow-y-auto p-4 bg-opacity-10 bg-black dark:bg-opacity-10 custom-scrollbar">
            {messages.map((msg, index) => (
              <div key={index} className={`mb-4 ${msg.sender === 'user' ? 'text-right' : 'text-left'}`}>
                <div className={`inline-block p-3 rounded-lg ${
                  msg.sender === 'user' 
                    ? 'bg-orange-500 text-white' 
                    : 'bg-black text-white'
                } shadow-md max-w-[80%] animate-fadeIn`}>
                  {msg.text}
                </div>
              </div>
            ))}
            {isTyping && (
              <div className="text-orange-300 text-sm animate-pulse flex items-center">
                <Zap size={16} className="mr-2" />
                AI is thinking...
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="p-4 bg-black border-t border-gray-700">
            <div className="flex items-center bg-gray-700 rounded-full shadow-inner overflow-hidden">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                className="flex-grow px-4 py-2 bg-transparent text-white placeholder-gray-400 focus:outline-none"
                placeholder="Type your message..."
              />
              <button onClick={handleSend} className="p-2 bg-gradient-to-r from-orange-500 to-orange-600 text-white rounded-full hover:from-orange-600 hover:to-orange-700 transition-colors">
                <Send size={20} />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <button
          onClick={toggleChat}
          className="bg-gradient-to-r from-orange-500 to-orange-600 text-white p-4 rounded-full shadow-lg hover:from-orange-600 hover:to-orange-700 transition-all flex items-center animate-pulse"
        >
          <MessageCircle size={24} className="mr-2" />
          <span className="font-bold">Chat with AI</span>
        </button>
      )}
    </div>
  );
};

const CustomStyles = () => (
  <style jsx global>{`
    @keyframes slideUp {
      from { transform: translateY(100%); opacity: 0; }
      to { transform: translateY(0); opacity: 1; }
    }
    @keyframes fadeIn {
      from { opacity: 0; transform: translateY(10px); }
      to { opacity: 1; transform: translateY(0); }
    }
    .animate-slideUp {
      animation: slideUp 0.3s ease-out;
    }
    .animate-fadeIn {
      animation: fadeIn 0.3s ease-out;
    }
    .custom-scrollbar::-webkit-scrollbar {
      width: 6px;
    }
    .custom-scrollbar::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
    }
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 3px;
    }
    .dark .custom-scrollbar::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1);
    }
    .dark .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.2);
    }
  `}</style>
);

const ChatBotWithCustomStyles = () => (
  <>
    <ChatBox />
    <CustomStyles />
  </>
);

export default ChatBotWithCustomStyles;
