import React from "react";
import { Link } from "react-router-dom";
import { Award, FileText, Flag, Projector } from "lucide-react";
import Footer from "../components/footer";

const AchievementCard = ({ icon, title, description }) => (
  <div className="bg-black p-6 rounded-lg shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-orange-500/20">
    <div className="text-orange-500 mb-4">{icon}</div>
    <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </div>
);

const AchievementSection = ({ title, items }) => (
  <div className="mb-16">
    <h2 className="text-3xl font-bold text-white mb-8 text-center">{title}</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {items.map((item, index) => (
        <AchievementCard key={index} {...item} />
      ))}
    </div>
  </div>
);

const Achievements = () => {
  const researchPapers = [
    {
      icon: <FileText size={48} />,
      title: "A survey of cybersecurity laws, regulations, and policies in technologically advanced nations",
      description: "Springer · Sep 20, 2024(other author:Farhan Hassan Saeed)",
     

    },
    {
      icon: <FileText size={48} />,
      title: "-A Survey of Bug Bounty Programs in Strengthening Cybersecurity and Privacy in the Blockchain Industr",
      description: "MDPI-Blockchains · Aug 8, 2024",
     
    },
    {
      icon: <FileText size={48} />,
      title: "DevicesDelSec: An Anti-Forensics Data Deletion Framework for Smartphones, IoT, and Edge Devices",
      description: "IEEE · May 23, 2024",
    },

    {
      icon: <FileText size={48} />,
      title: "Smart Cities: A Novel Framework for Energy Production and Harvesting using Renewable Energy",
      description: "IEEE · Oct 10, 2023",
    },

    {
      icon: <FileText size={48} />,
      title: "Revolutionizing Data Center Networks: Dynamic Load Balancing via Floodlight in SDN Environment",
      description: "IEEE · Feb 19, 2024",
    },

    {
      icon: <FileText size={48} />,
      title: "Smartphone Security and Privacy: A Survey on APTs, Sensor-Based Attacks, Side-Channel Attacks, Google Play Attacks, and Defenses",
      description: "Technologies, MDPI. · Jun 12, 2023",
    },

  
  ];

  const ctfCompetitions = [
    {
      icon: <Flag size={48} />,
      title: "Blackhat Mea 2024 ",
      description: "secured 6th place in Pakistan, 2nd in Air University Islamabad, and 55th globally",
    },
    {
      icon: <Flag size={48} />,
      title: "Ignite hackathon 2023",
      description: "3rd position in red team, and 5 position in overall competition",
    },
    {
      icon: <Flag size={48} />,
      title: "BSides Fargo 2.0 USA ",
      description: "Secured the 4th position in the BSides Fargo 2.0 Hackathon",
    },
    
  ];

  const pentestingAwards = [
    {
      icon: <Flag size={48} />,
      title: "Invitation from NUST ",
      description: "Invited to present research paper as an author at the ICAI Conference 2023 hosted by NUST.",
    },
    {
      icon: <Flag size={48} />,
      title: "Invitation from FAST",
      description: "Invited to present research paper as an author at the ICIT Conference 2023 hosted by FAST University.",
    },
    {
      icon: <Flag size={48} />,
      title: "Invited at UOL ",
      description: "Invited to present research paper as an author at the ICACS Conference 2024 hosted by The University of Lahore.",
    },
    {
      icon: <Flag size={48} />,
      title: "Invited at NUML ",
      description: "Invited to present research paper as an author at the ICECT Conference 2024 hosted by NUML University.",
    },
  ];

  const projects = [
    {
      icon: <Projector size={48} />,
      title: "Anti-Phishing Tool",
      description: "Developed an anti-phishing system that identifies and mitigates phishing attempts by detecting false emails and malicious domains",
    },
    {
      icon: <FileText size={48} />,
      title: "Chat Application",
      description: "Developing a Linux TCP socket-based client/server chat application in C, with CRC for error detection and Hamming code for single-bit error correction",
    },
    {
      icon: <FileText size={48} />,
      title: "Gmail Phishing",
      description: "Developed a Gmail login phishing page hosted on an SSL-certified URL for educational purposes in understanding phishing tactics and security vulnerabilities.",
    },
  ];

  return (
    <div>
      <div className="bg-black/80 min-h-screen">
        <section className="relative md:py-44 py-32 bg-[url('../../assets/images/bg/aboutusbg.png')] bg-no-repeat bg-bottom bg-cover">
          <div className="container relative">
            <div className="grid grid-cols-1 text-center mt-6">
              <div>
                <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">
                  Achievements
                </h5>
              </div>
              <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
                <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                  <Link to="/">NexGen Guards</Link>
                </li>
                <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                  <i className="mdi mdi-chevron-right"></i>
                </li>
                <li
                  className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white"
                  aria-current="page"
                >
                  Achievements
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="py-20 px-4 md:px-0">
          <div className="container mx-auto max-w-6xl">
            <AchievementSection
              title="Research Papers"
              items={researchPapers}
            />

            <AchievementSection
              title="Projects"
              items={projects}
            />

            <AchievementSection
              title="CTF Competitions"
              items={ctfCompetitions}
            />
            <AchievementSection
              title="Other Achievments"
              items={pentestingAwards}
            />
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default Achievements;
