import blog1 from "../assets/images/blog/1.jpg"
import blog2 from "../assets/images/blog/2.jpg"
import blog3 from "../assets/images/blog/3.jpg"
import blog4 from "../assets/images/blog/4.jpg"
import blog5 from "../assets/images/blog/5.jpg"
import blog6 from "../assets/images/blog/6.jpg"
import blog7 from "../assets/images/blog/7.jpg"
import blog8 from "../assets/images/blog/8.jpg"
import blog9 from "../assets/images/blog/9.jpg"
import client1 from '../assets/images/client/01.jpg'
import client2 from '../assets/images/client/02.jpg'
import client3 from '../assets/images/client/03.jpg'
import client4 from '../assets/images/client/04.jpg'
import client5 from '../assets/images/client/05.jpg'
import client6 from '../assets/images/client/06.jpg'
import client7 from '../assets/images/client/07.jpg'
import client8 from '../assets/images/client/08.jpg'


export const blogData = [
    {
        id:1,
        image:blog1,
        title:'A Survey of Bug Bounty Programs in Strengthening Cybersecurity and Privacy in the Blockchain Industry.',
        author:'Springer',
        client:'https://www.mdpi.com/2813-5288/2/3/10',
        date:'July 2024'
    },
    {
        id:2,
        image:blog2,
        title:"A Survey of Cybersecurity Laws, Regulations, and Policies in Technologically Advanced Nations: ",
        author:'MDPI-Blockchains',
        client:'https://link.springer.com/article/10.1365/s43439-024-00128-y  ',
        date:'Nov 29, 2023'
    },
    {
        id:3,
        image:blog3,
        title:'Delsec: An Anti-Forensics Data Deletion Framework for Smartphones, IoT, and Edge Devices.',
        author:'IEEE',
        client:'https://ieeexplore.ieee.org/abstract/document/10581213',
        date:'Dec 29, 2023'
    },
    {
        id:4,
        image:blog4,
        title:'A Novel Framework for Energy Production and Harvesting Using Renewable Energy.',
        author:'IEEE',
        client:'https://ieeexplore.ieee.org/abstract/document/10335837',
        date:'March 13, 2023'
    },
    {
        id:5,
        image:blog5,
        title:'A Survey on APTs, Sensor-Based Attacks, Side-Channel Attacks, Google Play Attacks, and Defenses.',
        author:'MDPI',
        client:'https://www.mdpi.com/2227-7080/11/3/76',
        date:'May 6, 2023'
    },
    {
        id:6,
        image:blog6,
        title:'A Cybersecurity Risk Assessment of Electric Vehicle Mobile Applications',
        author:'IEEE (ICAI)',
        client:'https://ieeexplore.ieee.org/document/10136682',
        date:'June 19, 2023'
    },
    {
        id:7,
        image:blog7,
        title:'Emerging Cybersecurity and Privacy Threats to Electric Vehicles and Their Impact on Human and Environmental Sustainability.',
        author:'MDPI',
        client:'https://www.mdpi.com/1996-1073/16/3/1113',
        date:'June 20, 2023'
    },
    {
        id:8,
        image:blog8,
        title:'Dynamic Load Balancing via Floodlight in SDN Environment.',
        author:'IEEE',
        client:'https://ieeexplore.ieee.org/abstract/document/10473246',
        date:'Aug 31, 2023'
    },
  
]