import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Shield, Code, Lock, Globe } from 'lucide-react';

import Navbar from "../components/navbar";
import BrandLogo from "../components/brandLogo";
import Features from "../components/features";
import AboutOne from "../components/aboutOne";
import AboutTwo from "../components/aboutTwo";
import AmazingFeatures from "../components/amazingFeatures";
import AboutThree from "../components/aboutThree";
import Pricing from "../components/pricing";
import Faq from "../components/Faq";
import Blogs from "../components/blogs";
import Footer from "../components/footer";

const GlowingButton = ({ children, className }) => (
  <button className={`relative group ${className}`}>
    <span className="absolute inset-0 bg-orange-500 rounded-md blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></span>
    <span className="relative block px-8 py-3 bg-orange-600 rounded-md text-white font-semibold">
      {children}
    </span>
  </button>
);

const CyclingText = () => {
  const words = ["Cyber Website", "AI", "Web Development"];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <span className="text-orange-500 inline-block h-16 w-full relative">
      {words.map((word, i) => (
        <span
          key={word}
          className={`absolute left-0 right-0 transition-all duration-500 ${
            i === index ? 'opacity-100 top-0' : 'opacity-0 top-8'
          }`}
        >
          {word}
        </span>
      ))}
    </span>
  );
};

const FloatingIcon = ({ Icon, top, left, size, color, animationDelay }) => (
  <div
    className="absolute animate-float"
    style={{
      top: `${top}%`,
      left: `${left}%`,
      animationDelay: `${animationDelay}s`,
    }}
  >
    <Icon size={size} color={color} />
  </div>
);

export default function Index() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.remove('light');
    document.documentElement.classList.add('dark-bg');
  }, []);

  return (
    <div className="bg-gradient-to-br from-gray-900 to-gray-800 text-white min-h-screen relative overflow-hidden">
      {/* Complex background */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-gray-800"></div>
        <div
          className="absolute right-0 top-0 w-1/2 h-full bg-orange-500 opacity-15 blur-3xl transform rotate-12 translate-x-1/4 -translate-y-1/4"
        ></div>
        <div
          className="absolute left-0 bottom-0 w-1/2 h-1/2 bg-orange-500 opacity-15 blur-3xl transform -rotate-12 -translate-x-1/4 translate-y-1/4"
        ></div>
      </div>

      <Navbar />

      {/* Content */}
      <div className="relative z-10">
        <div className="mt-40 relative">
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-96 h-96 bg-orange-500 rounded-full filter blur-3xl opacity-20 animate-pulse"></div>
          </div>
          <FloatingIcon Icon={Shield} top={10} left={10} size={32} color="#ff6600" animationDelay={0} />
          <FloatingIcon Icon={Code} top={20} left={80} size={32} color="#ff6600" animationDelay={1} />
          <FloatingIcon Icon={Lock} top={70} left={20} size={32} color="#ff6600" animationDelay={2} />
          <FloatingIcon Icon={Globe} top={60} left={80} size={32} color="#ff6600" animationDelay={3} />
          <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 text-center relative">
            <h1 className="text-5xl font-bold mb-6 flex flex-col items-center">
              <span className="text-white">We Protect Your</span>
              <CyclingText />
            </h1>
            <p className="text-gray-300 max-w-2xl mx-auto mb-10 text-xl">
              Securing your digital assets with cutting-edge technology and unparalleled expertise.
            </p>
            <GlowingButton className="text-lg">
              Get Started
            </GlowingButton>
          </main>
        </div>
      </div>

      <main>
        <section className="relative md:py-24 py-16">
          <Features classlist="container relative"/>
          <AboutOne />
          <AboutTwo />
          <AmazingFeatures />
          <AboutThree />

          <div className="container relative md:mt-24 mt-16">
            <div className="grid grid-cols-1 pb-6 text-center">
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                The right plans, <br/> 
                <span className="bg-gradient-to-br from-orange-400 to-orange-600 text-transparent bg-clip-text">
                  for the right price
                </span>
              </h3>
              <p className="text-gray-300 max-w-xl mx-auto">
                Artificial intelligence makes it fast easy to create content for your blog, social media, website, and more!
              </p>
            </div>
            <Pricing />
          </div>

          <Faq />
          <Blogs />
        </section>
      </main>

      <Footer />
    </div>
  );
}